<template>
  <div class="">
    <div class="">
      <subscribe-header
        :hours="hours"
        :days="days"
        :minutes="minutes"
        :seconds="second"
        :showCountDown="showCountDown"
        :isLoading="isLoading"
        :showSubscribeHeader="subscribingVisibility"
        :showText="showText"
        @registration="openRegistration"
      />
      <div class="h-full flex flex-col">
        <!-- <div class="max-w-screen-2xl w-full mx-auto pt-6 px-6 flex-grow"> -->
        <div
          class="
            bg-main-img bg-no-repeat bg-center bg-cover
            w-full
            mx-auto
            flex-grow
            overflow-hidden
          "
        >
          <!-- <div class="bg-main-img rounded-t-2xl h-full">
              <div class=""></div>
          </div> -->
          <div class="max-w-screen-2xl w-full mx-auto px-6 h-full">
            <div
              class="
                h-full
                py-6
                sm:p-12
                flex
                items-center
                justify-center
                sm:justify-start
              "
            >
              <!-- header mobile -->
              <header-mob />
              <!-- end header mobile -->
            </div>
          </div>
        </div>
        <div id="subscribe" class="border-t border-b w-full">
          <div class="max-w-screen-2xl w-full mx-auto px-6">
            <div class="flex flex-col sm:flex-row justify-between">
              <div class="py-4 lg:py-8">
                <div v-if="showText" class="">
                  <div class="sm:h-5"></div>
                  <div
                    class="
                      mt-6
                      flex
                      items-center
                      text-2xl
                      sm:text-3xl
                      md:text-4xl
                      lg:text-5xl
                      xl:text-6xl
                      font-head font-bold
                      text-header
                      leading-none
                      h-12
                      md:h-16
                      lg:h-16
                      xl:h-20
                    "
                  >
                    APMINK.LT jau greitai!
                  </div>
                  <div class="content-text">
                    Pažink Lietuvą su dviračių turų žaidimais!
                    <br />
                    Daugiau informacijos
                    <a
                      href="https://www.facebook.com/apmink.lt"
                      target="_blank"
                      class="
                        font-bold
                        transition
                        duration-500
                        ease-in-out
                        hover:text-over
                      "
                      >APMINK.lt facebook puslapyje ></a
                    >
                  </div>
                </div>
                <div v-else class="">
                  <div class="title-text leading-none">Iki APMINK.LT liko:</div>
                  <div
                    v-if="showCountDown && !isLoading"
                    class="mt-6 timer-text leading-none"
                  >
                    {{ days }}<span class="title-text">d</span> {{ hours
                    }}<span class="title-text">h</span> {{ minutes
                    }}<span class="title-text">m</span> {{ second
                    }}<span class="title-text">s</span>
                  </div>
                  <div v-if="isLoading" class="pt-6 pb-2">
                    <img
                      class="h-10 md:h-12 xl:h-16 pl-32"
                      src="../../assets/loading-spinner.gif"
                      alt=""
                    />
                  </div>
                  <div class="content-text">
                    Pažink Lietuvą su dviračių žygiais-žaidimais jau greitai!
                  </div>
                </div>
              </div>
              <div
                class="
                  py-4
                  lg:py-8
                  border-t
                  sm:border-l
                  w-full
                  sm:w-5/12
                  sm:pl-4
                  md:pl-12
                "
              >
                <div class="title-text leading-none">Dovana:</div>
                <div
                  class="
                    mt-6
                    font-head
                    text-80
                    font-bold
                    text-header
                    leading-none
                    flex
                    items-center
                  "
                >
                  <svg
                    class="h-14 w-14 md:h-16 md:w-16 xl:h-20 xl:w-20"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 87.76 87.76"
                  >
                    <path d="M0,0H87.76V87.76H0Z" fill="none" />
                    <path
                      d="M67.82,16.627H59.849A10.944,10.944,0,0,0,40.4,6.937l-1.828,2.45L36.738,6.9a10.973,10.973,0,0,0-20.112,6.07,10.986,10.986,0,0,0,.658,3.657H9.313A7.257,7.257,0,0,0,2.037,23.94L2,64.164a7.288,7.288,0,0,0,7.313,7.313H67.82a7.288,7.288,0,0,0,7.313-7.313V23.94A7.288,7.288,0,0,0,67.82,16.627ZM49.537,9.313A3.657,3.657,0,1,1,45.88,12.97,3.667,3.667,0,0,1,49.537,9.313Zm-21.94,0A3.657,3.657,0,1,1,23.94,12.97,3.667,3.667,0,0,1,27.6,9.313Zm36.567,54.85H12.97a3.667,3.667,0,0,1-3.657-3.657V45.88L67.82,56.85v3.657A3.667,3.667,0,0,1,64.164,64.164ZM67.82,45.88V56.85L9.313,45.88V27.6A3.667,3.667,0,0,1,12.97,23.94H27.889l-5.412,7.386a3.65,3.65,0,1,0,5.887,4.315l10.2-13.9,10.2,13.9a3.65,3.65,0,1,0,5.887-4.315L49.244,23.94H64.164A3.667,3.667,0,0,1,67.82,27.6Z"
                      transform="translate(5.313 5.313)"
                      fill="#468404"
                    />
                  </svg>
                  <button
                    @click="openRegistration"
                    class="
                      text-white
                      rounded-r30
                      text-xs
                      sm:text-sm
                      md:text-lg
                      xl:text-xl
                      h-10
                      md:h-12
                      xl:h-16
                      uppercase
                      px-3
                      sm:px-4
                      xl:px-6
                      flex
                      items-center
                      ml-2
                      sm:ml-4
                      font-bold
                      tracking-widest
                      transition
                      duration-500
                      ease-in-out
                      bg-header
                      hover:bg-over
                    "
                  >
                    <div>
                      Užsisakyk
                      <span class="sm:hidden lg:inline">naujienas</span>
                    </div>
                    <div class="ml-0 xl:ml-4 h-6 w-6 xl:h-10 xl:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0,0H24V24H0Z" fill="none" />
                        <path
                          d="M5,13H16.17l-4.88,4.88a1.008,1.008,0,0,0,0,1.42,1,1,0,0,0,1.41,0l6.59-6.59a1,1,0,0,0,0-1.41L12.71,4.7A1,1,0,1,0,11.3,6.11L16.17,11H5a1,1,0,0,0,0,2Z"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
                <div class="content-text">
                  Visiems užsisakiusiems naujienas iki APMINK.lt paleidimo
                  pradžios dovanosime pirmą išsirinktą dviračių žygį-žaidimą
                  nemokamai
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <main-content />
  <main-footer />
  <registration-form
    :open="formVisibility"
    @close="hideForm"
  ></registration-form>
</template>

<script>
import MainContent from "./MainContent.vue";
import RegistrationForm from "./RegistrationForm.vue";
import SubscribeHeader from "./SubscribeHeader.vue";
import MainFooter from "./MainFooter.vue";
import HeaderMob from "./HeaderMob.vue";

export default {
  components: {
    MainContent,
    RegistrationForm,
    SubscribeHeader,
    MainFooter,
    HeaderMob,
  },
  data() {
    return {
      countDownDate: null,
      days: 0,
      hours: 0,
      minutes: 0,
      second: 0,
      showCountDown: false,
      isLoading: true,
      formVisibility: false,
      subscribingVisibility: false,
      showText: true,
    };
  },
  methods: {
    enter(el) {
      el.classList.add("kuku");
      // observer.observe(el);
    },
    openRegistration() {
      this.formVisibility = true;
    },
    hideForm() {
      this.formVisibility = false;
    },
    getDate() {
      fetch(
        "https://vue-apmink-default-rtdb.europe-west1.firebasedatabase.app/startdate.json"
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              "Oi, įvyko kažkokia klaida. Pabandykite vėliau arba susisiekite su mumis"
            );
          }
        })
        .then((data) => {
          const results = [];
          for (const id in data) {
            results.push({
              id: id,
              startDate: data[id].startDate,
            });
          }
          this.countDownDate = new Date(
            results[results.length - 1].startDate
          ).getTime();
          this.startCountDown();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    startCountDown() {
      const self = this;
      let x = setInterval(function () {
        let now = new Date().getTime();
        let distance = self.countDownDate - now;
        if (distance < 0) {
          clearInterval(x);
          self.showCountDown = false;
          return;
        }
        self.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        self.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        self.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        self.second = Math.floor((distance % (1000 * 60)) / 1000);

        self.showCountDown = true;
        self.isLoading = false;
      }, 1000);
    },
    fixingToTop() {
      const subscribe = document.getElementById("subscribe");
      const elHeight = subscribe.offsetHeight / 2;
      const positionTop = subscribe.offsetTop + elHeight;

      //   console.log(window.pageYOffset + ">" + positionTop);
      if (window.pageYOffset >= positionTop) {
        this.subscribingVisibility = true;
      } else {
        this.subscribingVisibility = false;
      }
    },
  },
  mounted() {
    this.getDate();
    const it = this;
    window.onscroll = function () {
      it.fixingToTop();
    };
  },
};
</script>

<style scoped>
</style>