<template>
  <div class="relative w-full">
    <!-- content header -->
    <section class="max-w-screen-xl w-full mx-auto px-6 pt-32">
      <div class="header-text text-header pb-20">
        Kas jūsų laukia <br />
        APMINK.LT
      </div>
    </section>
    <!-- content card -->
    <section class="max-w-screen-xl w-full mx-auto pb-32">
      <div v-if="triggerCard" class="">
        <main-card />
      </div>
      <div v-else class="">
        <slider-content />
      </div>
    </section>
    <div id="gradient" class="absolute flex top-40 justify-center w-full">
      <img
        class="gradient-2 right-1/2 z-0"
        src="../../assets/gradient2.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import MainCard from "./MainCard.vue";
import SliderContent from "./SliderContent.vue";
export default {
  components: {
    MainCard,
    SliderContent,
  },
  data() {
    return {
      triggerCard: false,
    };
  },
  methods: {
    checkCard() {
      this.triggerCard = window.matchMedia("(min-width: 640px)").matches;
    },
    changeCard(event) {
      this.triggerCard = event;
    },
  },
  mounted() {
    this.checkCard();
    let wid = window.matchMedia("(min-width: 640px)");
    const it = this;
    wid.addEventListener("change", (e) => {
      it.changeCard(e.matches);
    });
  },
};
</script>

<style>
#gradient {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.gradient-2 {
   -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none; 
}
/* .before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
} */
</style>