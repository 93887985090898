<template>
  <div class="grid grid-cols-2 grid-rows-2 gap-6 xl:gap-14 px-6">
    <transition appear @enter="enter">
      <slider-card class="card">
        <template #image>
          <img class="mx-auto" src="../../assets/Icon_keliauk.png" alt="keliau ir pazink">
        </template>
        <template #title> KELIAUK IR PAŽINK </template>
        <template #content>
          Išsirink ir pravažiuok specialiai paruoštais įdomiais dviračių
          maršrutais gražiausiomis Lietuvos vietomis.
          <br />
          <br />
          Kiekvieną savaitę pristatysime ir įkelsime naują dviračių
          žygį-žaidimą.
        </template>
      </slider-card>
    </transition>

    <transition appear @enter="enter1">
      <slider-card>
        <template #image>
          <img class="mx-auto" src="../../assets/Icon_zaisk.png" alt="zaisk">
        </template>
        <template #title> ŽAISK </template>
        <template #content>
          Su žemėlapiu į telefoną gausi įdomių objektų atvaizdus ar kitas
            užduotis, atrask juos kelyje, nusifotografuok jų fone ir įkelk
            nuotraukas.
        </template>
      </slider-card>
    </transition>

    <transition appear @enter="enter">
      <slider-card>
        <template #image>
            <img class="mx-auto" src="../../assets/Icon_kviesk-draugus.png" alt="zaisk">
        </template>
        <template #title> KVIESK DRAUGUS </template>
        <template #content>
          Pakviesk į žygį-žaidimą draugus, šeimą. Keliaukite po Lietuvą
          dviračiais, žaiskite kartu ir smagiai leiskite laiką žygiuose iki pat
          vėlyvo rudens.
        </template>
      </slider-card>
    </transition>

    <transition appear @enter="enter1">
      <slider-card>
        <template #image>
            <img class="mx-auto" src="../../assets/Icon_laimek.png" alt="zaisk">
        </template>
        <template #title> NUGALĖK </template>
        <template #content>
          Rink taškus už surastus objektus ir įvykdytas užduotis, varžykis ir
          nugalėk bendroje įskaitoje.
        </template>
      </slider-card>
    </transition>
  </div>
</template>

<script>
const option = {
  root: null,
  threshold: 0.3,
  rootMargins: "0px",
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("enter1");
      observer.unobserve(entry.target);
    }
  });
}, option);

const observer1 = new IntersectionObserver((entries, observer1) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      setTimeout(() => {
        entry.target.classList.add("enter1");
        observer1.unobserve(entry.target);
      }, 300);
    }
  });
}, option);

import SliderCard from "./SliderCard.vue";
export default {
  components: {
    SliderCard,
  },
  methods: {
    enter(el) {
      el.classList.add("before-enter1");
      observer.observe(el);
    },
    enter1(el) {
      el.classList.add("before-enter1");
      observer1.observe(el);
      // console.log("kuku");
    },
  },
  mounted() {
    // const cardOne = document.querySelector(".card");
    // observer.observe(cardOne);
  },
};
</script>


<style scoped>
.before-enter1 {
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease;
}

.enter1 {
  opacity: 1;
  transform: translateY(0px);
}
</style>