<template>
    <user-auth 
        v-if="!isAuthenticate" 
        @auth-admin="authAdmin"
        >
    </user-auth>
  <div v-else  class="container mx-auto p-6">
      <div class="">
          <starting-date :admin="adminAuth" @logout="logoutAdmin">
              <!-- <p>Labas rytas</p> -->
          </starting-date>
          <create-admin />
      </div>
      <div v-if="!isLoading && results && results.length > 0" class="w-full mt-14">

          <div class="header-text text-header mb-4">
              Užsisakė naujienas
          </div>
          <table class="border-collapse border border-header table-auto w-full">
              <thead class="">
                  <tr>
                      <th class="border border-header">#</th>
                      <th class="border border-header">Email</th>
                      <th class="border border-header">Miestas</th>
                      <th class="border border-header">Maršruto trukme</th>
                      <th class="border border-header"> Šaltinis</th>
                      <th class="border border-header"> FB Šaltinis</th>
                      <th class="border border-header">Data</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(result, index) in results" :key="result.id">
                      <td class="border border-header">{{ index+1 }}</td>
                      <td class="border border-header">{{ result.email }}</td>
                      <td class="border border-header">{{ result.city }}</td>
                      <td class="border border-header">{{ result.duration }}</td>
                      <td class="border border-header">{{ result.comeFrom }}</td>
                      <td class="border border-header">{{ result.fromFb }}</td>
                      <td class="border border-header">{{ result.time }}</td>
                  </tr>
              </tbody>
          </table>


            <!-- <button class="bg-red-500" @click="loadRegistration">Uzkrauti</button> -->
      </div>
   
      <p v-if="isLoading">Loading...</p>
      <p v-if="!isLoading && (!results || results.length === 0) && !error">Nera užsiregitravusiu</p>
      <p v-if="!isLoading && error">{{error}}</p>
      
  </div>
  <!-- <admin-list /> -->

</template>

<script>
import UserAuth from './UserAuth.vue'
import StartingDate from './StartingDate.vue'
import CreateAdmin from './CreateAdmin.vue'
// import AdminList from './AdminList.vue'

// let timer;

export default {
    // inject: ['timeout'],
    components: {
        StartingDate,
        UserAuth,
        CreateAdmin
        // AdminList
    },
    data(){
        return {
            results: [],
            isLoading: false,
            error: null,
            startDate: null,
            // isAuthenticate: false,
            adminAuth: {},
            timer: null,
            regNumber: 0
        };
    },
    // watch: {
    //     adminAuth(){
    //         this.isAuthenticate;
    //     }
    // },
    computed: {
        isAuthenticate(){
            return this.adminAuth.token && this.adminAuth.userId;
           
        }
    },
    methods: {
        loadRegistration(){
            this.isLoading = true;
            this.error = null;
            const token = this.adminAuth.token;
            // console.log(token);
            fetch(`https://vue-apmink-default-rtdb.europe-west1.firebasedatabase.app/registration.json?auth=` + token)
            .then(response => {
                if(response.ok){
                    return response.json();
                } else {
                    throw new Error('Oi, įvyko kažkokia klaida. Pabandykite vėliau arba susisiekite su mumis');
                }
            }).then((data) => {
                this.isLoading = false;
                const results = [];
                for(const id in data){
                    results.push({ 
                        id: id, 
                        city: data[id].city, 
                        duration: data[id].duration, 
                        email: data[id].email, 
                        time: data[id].time,
                        comeFrom: data[id].comeFrom,
                        fromFb: data[id].fromFb
                    });
                }
                this.results = results;
            }).catch((error) => {
                this.isLoading = false;
                console.log(error);
                this.error = error.message;
            });
                
        },
        authAdmin(timeout){
            this.createAdmin();
            this.loadRegistration();
            this.setTimer(timeout);
            console.log(timeout);
        },
        logoutAdmin(){
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('tokenExpiration');
            clearTimeout(this.timer);
            this.adminAuth = {}
            this.$router.push('/');
        },
        createAdmin(){
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');

            if(token && userId) {
                this.adminAuth = {
                    token: token,
                    userId: userId
                },
                this.loadRegistration();
            }
        },
        setTimer(timeout){
            this.timer = setTimeout(
                this.logoutAdmin
            , timeout);
            // console.log(this.timer, 'is settimout');
        }
    },
    mounted() {
        // if(this.isAuthenticate){
        //     this.loadRegistration();
        // }
    },
    updated() {
        // console.log('uzkrauta' + this.adminAuth.email)
        // this.loadRegistration();
        // console.log('updated');
        // veikia be sustojimo
    },
    created(){
        this.createAdmin();

        const tokenExpiration = localStorage.getItem('tokenExpiration');
        if(tokenExpiration){
            const now = new Date().getTime();
            let differents = tokenExpiration - now;
            // console.log(differents);
            if (differents > 0) {
                this.setTimer(differents);
            } else {
                differents = 0;
                this.setTimer(differents);
            }
        }
        
        // console.log(timer);
        // console.log(this.timer);

    }

}
</script>

<style>

</style>