<template>
  <div class="mt-14 border-b pb-8">
    <div class="title-text mb-4">Sukurti naują Administratorių</div>
    <form @submit.prevent="submitForm">
      <div class="content-text mb-2">
        <label for="email">E-mail</label>
      </div>
      <div class="mb-6">
        <input
          class="border border-header rounded-r30 h-10 md:h-12 w-full max-w-sm px-6 text-sm md:text-base text-content"
          type="email"
          id="email"
          v-model.trim="email"
        />
      </div>
      <div class="content-text mb-2">
        <label for="password">Password</label>
      </div>
      <div class="mb-6">
        <input
          class="border border-header rounded-r30 h-10 md:h-12 w-full max-w-sm px-6 text-sm md:text-base text-content"
          type="password"
          id="password"
          v-model.trim="password"
        />
      </div>
      <p v-if="!formIsValid" class="">
        Įveskite galiojanti el.pašta ir slaptažodį iš mažiausiai 6 simbolių
      </p>
      <button
        class="font-head text-white rounded-r30 text-sm md:text-lg xl:text-xl h-10 md:h-12 xl:h-16 uppercase px-4 xl:px-6 flex items-center ml-4 font-bold tracking-widest transition duration-500 ease-in-out bg-header hover:bg-over"
      >
        Sukurti
      </button>
      <!-- <button class="rounded bg-red-500" @click="switchAuthMode">{{ switchModeButtonCaption }}</button> -->
    </form>
    <div class="">
      <p v-if="error" class="content-text text-red-500">Ivyko klaida: {{ error }}</p>
      <p v-if="isLoading" class="content-text">Loading...</p>
    </div>
  </div>
  <div v-if="isCreated" class="content-text">Administratorius sukurtas sekmingai</div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      formIsValid: true,
      isLoading: false,
      error: null,
      isCreated: false,
    };
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;
      try {
        this.error = null;
        await this.signupAdmin();
      } catch (err) {
        this.error = err.message || "Nepavyko prisijungti, pabandykite vėliau";
      }

      this.isLoading = false;
    },
    async signupAdmin() {
      const response = await fetch(
        "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD6XIVAyFpkj9k6ek9ZVaA8yhBrBjvQSkc",
        {
          method: "POST",
          body: JSON.stringify({
            email: this.email,
            password: this.password,
            returnSecureToken: true,
          }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        // console.log(responseData);
        const error = new Error(
          responseData.message || "Pabandykite nurodyti kita email"
        );
        throw error;
      }

      this.email = "";
      this.password = "";

      this.adminCreated();

      // console.log(responseData);

      // const setAdmin = {
      //     token: responseData.idToken,
      //     userId: responseData.localId,
      //     tokenExpiration: responseData.expiresIn
      // }

      // console.log(setAdmin);
    },
    adminCreated() {
      this.isCreated = true;
      const it = this;
      setTimeout(() => {
        it.isCreated = false;
      }, 3000);
    },
  },
};
</script>

<style>
</style>