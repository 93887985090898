<template>
  <div class="bg-content bg-opacity-5 border-2 border-white rounded-r70 p-12 max-w-cards w-full h-full z-10">
    <div class="mx-auto card-flex-direction h-full">
      <div class="flex-1 mb-4">
          <slot name="image"></slot>
      </div>
      <div class="w-8"></div>
      <div class="flex-1">
        <div class="title-text mb-6">
            <slot name="title"></slot>
        </div>
        <div class="content-text">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>