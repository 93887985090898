import { createApp } from 'vue'

import App from './App.vue'
import Router from './router.js'
import './index.css'
import ScrollAnimation from './directives/scrollanimation.js'
// import 'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.8/ScrollMagic.min.js'



const app = createApp(App);
app.use(Router);
app.directive('scrollanimation', ScrollAnimation);
// app.component(VueCountdown.name, VueCountdown);
app.mount('#app');