<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <main>
        <router-view ></router-view>
  </main>
</template>

<script>
// import 'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.8/ScrollMagic.min.js'


export default {
  data(){
    return {
      // startDate: null
    };
  },
  mounted(){
    // let magic = document.createElement('script');
    // magic.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.8/ScrollMagic.min.js');
    // // magic.setAttribute('integrity','sha512-8E3KZoPoZCD+1dgfqhPbejQBnQfBXe8FuwL4z/c8sTrgeDMFEnoyTlH3obB4/fV+6Sg0a0XF+L/6xS4Xx1fUEg==');
    // document.body.appendChild(magic);

    // let indicators = document.createElement('script');
    // indicators.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.8/plugins/debug.addIndicators.min.js');
    // document.body.appendChild(indicators);

    // let gsap = document.createElement('script');
    // gsap.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.8/plugins/animation.gsap.min.js');
    // document.body.appendChild(gsap);

    // let GASP = document.createElement('script');
    // GASP.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.1/gsap.min.js');
    // document.body.appendChild(GASP);

    // let cssPlugin = document.createElement('script');
    // cssPlugin.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.1/CSSRulePlugin.min.js');
    // document.body.appendChild(cssPlugin);



    // console.log('uzsikrove');
  }

}
</script>




<style>
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');

* {
  box-sizing: border-box;
}
body {
  margin: 0;
}


</style>
