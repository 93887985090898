import { createRouter, createWebHistory } from 'vue-router';

import RegistrationResults from './components/backend/RegistrationResults.vue';
import MainHeader from './components/frontend/MainHeader.vue';
// import UserAuth from './components/backend/UserAuth.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{ path: '/', component: MainHeader },
		{ path: '/ap-zaidimas', component: RegistrationResults },
        // { path: '/auth', component: UserAuth},
		{ path: '/:notFound(.*)', redirect: '/' },
	]
});

export default router;