<template>
  <section>
      <div class="rounded-t-r70 bg-header">
          <div class="max-w-screen-2xl w-full mx-auto px-6 text-white pt-10 md:pt-20 pb-10 leading-tight">
              <div class="header-text mb-16">
                  Iki greito pasimatymo APMINK.LT
                  <br>
                  Lauksime!
              </div>
              <div class="content-text flex items-center">
                  <div class="text-white">
                      © 2021 APMINK.LT pažintiniai dviračių žygiai-žaidimai <span class="font-thin"><a href="mailto: info@apmink.lt">info@apmink.lt</a></span>
                  </div>
                  <div class="ml-10">
                      <a href="https://www.facebook.com/apmink.lt">
                          <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67"><g transform="translate(0 -0.271)"><rect width="67" height="67" transform="translate(0 0.271)" fill="none"/><path d="M58.059,30.029A28.029,28.029,0,1,0,24.424,57.5V38.438H18.818V30.029h5.606V23.022a9.821,9.821,0,0,1,9.81-9.81h7.007v8.409H35.635a2.811,2.811,0,0,0-2.8,2.8v5.606h8.409v8.409H32.832v19.48A28.026,28.026,0,0,0,58.059,30.029Z" transform="translate(3.606 3.606)" fill="#fff"/></g></svg>
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>