<template>
  <div class="border-b pb-8">
    <div class="flex justify-between mb-6 border-b pb-4">
      <button
        @click="returnToMain"
        class="font-head h-10 md:h-12 text-sm md:text-lg rounded-r30 uppercase px-4 xl:px-6 flex items-center ml-2 font-bold tracking-widest transition duration-500 ease-in-out bg-white text-header border-2 border-header hover:bg-over hover:text-white hover:border-over"
      >
        Grįžti į puslapį
      </button>
      <button
        @click="logout"
        class="font-head h-10 md:h-12 text-sm md:text-lg rounded-r30 uppercase px-4 xl:px-6 flex items-center ml-2 font-bold tracking-widest transition duration-500 ease-in-out bg-white text-red-500 border-2 border-red-500 hover:bg-red-500 hover:text-white hover:border-white"
      >
        Atsijungti
      </button>
    </div>
    <div class="content-text">Projekto paleidimo data:</div>
    <div class="title-text mb-6">{{ setDate }}</div>
    <div class="content-text">
      <label for="date">Pakeisti paleidimo data</label>
    </div>
    <div class="">
      <input
        type="date"
        id="date"
        class="border border-header rounded-r30 h-10 md:h-12 w-full max-w-sm px-6 text-sm md:text-base text-content"
        v-model="startDate"
      />
    </div>
    <div v-if="startDate && !isLoadingDate" class="flex w-full max-w-sm mt-2">
      <button class="font-head h-10 md:h-12 text-sm md:text-lg rounded-r30 uppercase px-4 xl:px-6 flex items-center ml-2 font-bold tracking-widest transition duration-500 ease-in-out bg-white text-header border-2 border-header hover:bg-over hover:text-white hover:border-over" @click="newDate">Vykdyti</button>
      <button class="font-head h-10 md:h-12 text-sm md:text-lg rounded-r30 uppercase px-4 xl:px-6 flex items-center ml-2 font-bold tracking-widest transition duration-500 ease-in-out bg-white text-red-500 border-2 border-red-500 hover:bg-red-500 hover:text-white hover:border-white" @click="cancelDate">Atsaukti</button>
    </div>
    <div v-else-if="isLoadingDate" class="">Loading...</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["admin"],
  emits: ["logout"],
  data() {
    return {
      startDate: null,
      isLoadingDate: false,
      setDate: null,
    };
  },
  methods: {
    newDate() {
      this.isLoadingDate = true;
      const token = this.admin.token;
      fetch(
        `https://vue-apmink-default-rtdb.europe-west1.firebasedatabase.app/startdate.json?auth=` +
          token,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate: this.startDate,
          }),
        }
      )
        .then((response) => {
          if (response.ok) {
            this.isLoadingDate = false;
            this.startDate = null;
            this.getDate();
            console.log("irasyta");
          } else {
            throw new Error("could not save data!");
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getDate() {
      fetch(
        "https://vue-apmink-default-rtdb.europe-west1.firebasedatabase.app/startdate.json"
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              "Oi, įvyko kažkokia klaida. Pabandykite vėliau arba susisiekite su mumis"
            );
          }
        })
        .then((data) => {
          const results = [];
          for (const id in data) {
            results.push({
              id: id,
              startDate: data[id].startDate,
            });
          }
          this.setDate = results[results.length - 1].startDate;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    cancelDate() {
      this.startDate = null;
    },
    returnToMain() {
      this.$router.push("/");
    },
    logout() {
      this.$emit("logout");
    },
  },
  mounted() {
    this.getDate();
  },
};
</script>

<style>
</style>