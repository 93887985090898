<template>
  <div class="overflow-hidden w-full relative z-10">
    <button
      @click="prevSlider"
      class="absolute top-1/2 left-0 focus:outline-none text-header hover:text-over -mt-6"
    >
      <svg
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 60 60"
      >
        <g transform="translate(3194 998) rotate(180)">
          <g transform="translate(3134 998) rotate(-90)">
            <rect width="60" height="60" fill="none" />
          </g>
          <path
            d="M25.263,50.527A25.264,25.264,0,0,1,7.4,7.4,25.263,25.263,0,1,1,43.127,43.127,25.1,25.1,0,0,1,25.263,50.527ZM18.037,22.96a2.485,2.485,0,1,0,0,4.969h7.455v4.448a1.231,1.231,0,0,0,1.239,1.246,1.207,1.207,0,0,0,.873-.377l6.933-6.932a1.235,1.235,0,0,0,0-1.765L27.6,17.617a1.211,1.211,0,0,0-.86-.36,1.229,1.229,0,0,0-1.251,1.23V22.96Z"
            transform="translate(3138.737 942.737)"
            fill=""
          />
        </g>
      </svg>
    </button>
    <button
      @click="nextSlider"
      class="absolute top-1/2 right-0 focus:outline-none text-header hover:text-over -mt-6"
    >
      <svg
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 60 60"
      >
        <g transform="translate(-3134 -938)">
          <g transform="translate(3134 998) rotate(-90)">
            <rect width="60" height="60" fill="none" />
          </g>
          <path
            d="M25.263,50.527A25.264,25.264,0,0,1,7.4,7.4,25.263,25.263,0,1,1,43.127,43.127,25.1,25.1,0,0,1,25.263,50.527ZM18.037,22.96a2.485,2.485,0,1,0,0,4.969h7.455v4.448a1.231,1.231,0,0,0,1.239,1.246,1.207,1.207,0,0,0,.873-.377l6.933-6.932a1.235,1.235,0,0,0,0-1.765L27.6,17.617a1.211,1.211,0,0,0-.86-.36,1.229,1.229,0,0,0-1.251,1.23V22.96Z"
            transform="translate(3138.737 942.737)"
            fill=""
          />
        </g>
      </svg>
    </button>
    <div
      class="slider flex flex-nowrap items-stretch w-full"
      :style="{ 'margin-left': '-' + 100 * sliderPos + '%' }"
      @touchstart="touchstart"
      @touchmove="touchmove"
      @touchend="touchend"
    >
      <div class="pb-8 w-full flex-none px-6">
        <slider-card>
          <template #image> 
            <img class="mx-auto" src="../../assets/Icon_keliauk.png" alt="">
          </template>
          <template #title> KELIAUK IR PAŽINK </template>
          <template #content>
            Išsirink ir pravažiuok specialiai paruoštais įdomiais dviračių
            maršrutais gražiausiomis Lietuvos vietomis.
            <br />
            <br />
            Kiekvieną savaitę pristatysime ir įkelsime naują dviračių
            žygį-žaidimą.
          </template>
        </slider-card>
      </div>
      <div class="pb-8 w-full flex-none px-6">
        <slider-card>
          <template #image>
            <img class="mx-auto" src="../../assets/Icon_zaisk.png" alt="zaisk">
          </template>
          <template #title> ŽAISK </template>
          <template #content>
            Su žemėlapiu į telefoną gausi įdomių objektų atvaizdus ar kitas
            užduotis, atrask juos kelyje, nusifotografuok jų fone ir įkelk
            nuotraukas.
          </template>
        </slider-card>
      </div>
      <div class="pb-8 w-full flex-none px-6">
        <slider-card>
          <template #image>
            <img class="mx-auto" src="../../assets/Icon_kviesk-draugus.png" alt="zaisk">
          </template>
          <template #title> KVIESK DRAUGUS </template>
          <template #content>
            Pakviesk į žygį-žaidimą draugus, šeimą. Keliaukite po Lietuvą
            dviračiais, žaiskite kartu ir smagiai leiskite laiką žygiuose iki
            pat vėlyvo rudens.
          </template>
        </slider-card>
      </div>
      <div class="pb-8 w-full flex-none px-6">
        <slider-card>
          <template #image>
            <img class="mx-auto" src="../../assets/Icon_laimek.png" alt="zaisk">
          </template>
          <template #title> NUGALĖK </template>
          <template #content>
            Rink taškus už surastus objektus ir įvykdytas užduotis, varžykis ir
            nugalėk bendroje įskaitoje.
          </template>
        </slider-card>
      </div>
    </div>
  </div>
  <!-- selectors -->
  <div class="flex w-full justify-center items-center h-8">
    <div v-for="index in maxSlide" :key="index">
      <div
        @click="changePosition(index)"
        class="rounded-full bg-header mx-4 cursor-pointer"
        :class="{
          'pos-none': index - 1 != sliderPos,
          pos: index - 1 === sliderPos,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import SliderCard from "./SliderCard.vue";
export default {
  components: {
    SliderCard,
  },
  data() {
    return {
      sliderPos: 0,
      maxSlide: 4,
      touch: {
        startX: 0,
        endX: 0,
      },
      sliderInterval: 5000,
      interval: null,
    };
  },
  methods: {
    prevSlider() {
      if (this.sliderPos > 0) {
        this.sliderPos--;
      } else {
        this.sliderPos = this.maxSlide -1
      }
    },
    nextSlider() {
      if (this.sliderPos >= this.maxSlide - 1) {
        this.sliderPos = 0;
      } else {
        this.sliderPos++;
      }
    },
    changePosition(index) {
      this.sliderPos = index - 1;
    },
    touchstart(event) {
      this.touch.startX = event.touches[0].clientX;
      this.touch.endX = 0;
    },
    touchmove(event) {
      this.touch.endX = event.touches[0].clientX;
    },
    touchend() {
      if (
        !this.touch.endX ||
        Math.abs(this.touch.endX - this.touch.startX) < 20
      )
        return;

      if (this.touch.endX < this.touch.startX) this.nextSlider();
      else this.prevSlider();
    },
    sliderSetInterval() {
      const it = this;
      it.interval = setInterval(() => {
        it.nextSlider();
      }, it.sliderInterval);
    },
  },
  mounted() {
    // this.sliderSetInterval();
  },
};
</script>

<style>
.slider {
  transition: all ease 0.5s;
}
.pos {
  width: 20px;
  height: 20px;
  transition: 0.5s;
}
.pos-none {
  width: 10px;
  height: 10px;
  transition: 0.5s;
}
</style>