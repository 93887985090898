<template>
  <transition name="modalbg">
    <div
      v-if="open"
      class="fixed bg-header bg-opacity-60 top-0 w-full h-full z-40"
      @click="closeMod"
    ></div>
  </transition>
  <transition name="modalcont">
    <div
      v-if="open"
      class="fixed bg-white top-0 right-0 w-11/12 lg:w-2/3 h-full z-50"
    >
      <div class="p-6 border-b">
        <button
          class="
            font-head
            h-10
            md:h-12
            text-sm
            md:text-lg
            rounded-r30
            uppercase
            px-4
            xl:px-6
            flex
            items-center
            ml-2
            font-bold
            tracking-widest
            transition
            duration-500
            ease-in-out
            bg-white
            text-header
            border-2 border-header
            hover:bg-over
            hover:text-white
            hover:border-over
          "
          @click="closeMod"
        >
          <svg
            class="fill-current mr-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g transform="translate(24 24) rotate(180)">
              <path d="M0,0H24V24H0Z" fill="none" />
              <path
                d="M5,13H16.17l-4.88,4.88a1.008,1.008,0,0,0,0,1.42,1,1,0,0,0,1.41,0l6.59-6.59a1,1,0,0,0,0-1.41L12.71,4.7A1,1,0,1,0,11.3,6.11L16.17,11H5a1,1,0,1,0,0,2Z"
                transform="translate(0 0)"
                fill=""
              />
            </g>
          </svg>
          Uždaryti
        </button>
      </div>
      <div class="overflow-y-scroll h-full w-full">
        <transition name="success" mode="out-in">
          <div v-if="!isSuccess" class="max-w-screen-sm mx-auto px-6">
            <div class="header-text text-header pb-8 mt-14">
              Užsakyti naujienas
            </div>
            <div class="flex items-center mb-20">
              <svg
                class="flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                width="87.76"
                height="87.76"
                viewBox="0 0 87.76 87.76"
              >
                <path d="M0,0H87.76V87.76H0Z" fill="none" />
                <path
                  d="M67.82,16.627H59.849A10.944,10.944,0,0,0,40.4,6.937l-1.828,2.45L36.738,6.9a10.973,10.973,0,0,0-20.112,6.07,10.986,10.986,0,0,0,.658,3.657H9.313A7.257,7.257,0,0,0,2.037,23.94L2,64.164a7.288,7.288,0,0,0,7.313,7.313H67.82a7.288,7.288,0,0,0,7.313-7.313V23.94A7.288,7.288,0,0,0,67.82,16.627ZM49.537,9.313A3.657,3.657,0,1,1,45.88,12.97,3.667,3.667,0,0,1,49.537,9.313Zm-21.94,0A3.657,3.657,0,1,1,23.94,12.97,3.667,3.667,0,0,1,27.6,9.313Zm36.567,54.85H12.97a3.667,3.667,0,0,1-3.657-3.657V45.88L67.82,56.85v3.657A3.667,3.667,0,0,1,64.164,64.164ZM67.82,45.88V56.85L9.313,45.88V27.6A3.667,3.667,0,0,1,12.97,23.94H27.889l-5.412,7.386a3.65,3.65,0,1,0,5.887,4.315l10.2-13.9,10.2,13.9a3.65,3.65,0,1,0,5.887-4.315L49.244,23.94H64.164A3.667,3.667,0,0,1,67.82,27.6Z"
                  transform="translate(5.313 5.313)"
                  fill="#468404"
                />
              </svg>
              <div class="content-text ml-4">
                Užsisakyk naujienas ir atsakyk į keletą klausimų iki APMINK.lt
                paleidimo pradžios ir mes dovanosime pirmą išsirinktą pažintinį
                dviračių žygį-žaidimą nemokamai.
              </div>
            </div>
            <form @submit.prevent="submitRegistration">
              <div class="text-base md:text-xl mb-8">
                <div class="mb-2">
                  <label for="email">Jūsų el. paštas*</label>
                </div>
                <div class="">
                  <input
                    class="
                      border border-header
                      rounded-r30
                      h-10
                      md:h-12
                      w-full
                      max-w-sm
                      px-6
                      text-sm
                      md:text-base
                      text-content
                    "
                    id="email"
                    name="city"
                    type="email"
                    v-model.trim="email"
                    @blur="validEmail"
                  />
                </div>
                <p
                  v-if="emailValidity === 'invalid'"
                  class="text-sm md:text-base text-red-500"
                >
                  Įveskite galiojantį el.paštą
                </p>
              </div>
              <div class="text-base md:text-xl mb-8">
                <div class="mb-2">
                  <label for="city">Miestas kuriame gyveni*</label>
                </div>
                <div class="">
                  <input
                    class="
                      rounded-r30
                      h-10
                      md:h-12
                      w-full
                      max-w-sm
                      px-6
                      text-sm
                      md:text-base
                      text-content
                    "
                    :class="{
                      ['border border-header']:
                        cityValidity === 'pending' || 'valid',
                      ['border border-red-500 border-4']:
                        cityValidity === 'invalid',
                    }"
                    id="city"
                    name="city"
                    type="text"
                    v-model.trim="city"
                    @blur="validInput"
                  />
                  <p
                    v-if="cityValidity === 'invalid'"
                    class="text-sm md:text-base text-red-500"
                  >
                    Įrašykite miestą, kuriame gyvenate
                  </p>
                </div>
              </div>
              <div class="text-base md:text-xl mb-8">
                <div class="mb-2">
                  Koks pažintinių dviračių žygių-žaidimų atstumas (laikas) jums
                  būtų tinkamiausias?*
                </div>
                <div class="text-sm md:text-base text-content mb-1">
                  <input
                    class="border-header"
                    id="trumpesnis"
                    name="trukme"
                    type="radio"
                    value="trumpesnis"
                    v-model="trukme"
                  />
                  <label class="ml-2" for="trumpesnis"
                    >Trumpesnis nei 20km (mažiau nei 2 val.)</label
                  >
                </div>
                <div class="text-sm md:text-base text-content mb-1">
                  <input
                    class="border-header"
                    id="dviTrys"
                    name="trukme"
                    type="radio"
                    value="2-3h"
                    v-model="trukme"
                  />
                  <label class="ml-2" for="dviTrys">20-35km (2-3 val.)</label>
                </div>
                <div class="text-sm md:text-base text-content mb-1">
                  <input
                    class="border-header"
                    id="trysKeturios"
                    name="trukme"
                    type="radio"
                    value="3-4h"
                    v-model="trukme"
                  />
                  <label class="ml-2" for="trysKeturios"
                    >35-50km (3-4 val.)</label
                  >
                </div>
                <div class="text-sm md:text-base text-content mb-1">
                  <input
                    class="border-header"
                    id="ilgesnis"
                    name="trukme"
                    type="radio"
                    value="ilgesnis"
                    v-model="trukme"
                  />
                  <label class="ml-2" for="ilgesnis"
                    >Ilgesnis nei 50km (daugiau nei 4 val.)</label
                  >
                </div>
                <p
                  v-if="!radioValidity"
                  class="text-sm md:text-base text-red-500"
                >
                  Paritinkite pageidaujamą atstumą
                </p>
              </div>

              <div class="my-14">
                <p class="text-sm md:text-base text-red-500 mb-2" v-if="error">
                  {{ error }}
                  <span
                    ><a class="text-header" href="mailto: info@apmink.lt"
                      >info@apmink.lt</a
                    ></span
                  >
                </p>
                <div class="flex justify-end">
                  <button @click="conversions"
                    class="
                      font-head
                      text-white
                      rounded-r30
                      text-sm
                      md:text-lg
                      xl:text-xl
                      h-10
                      md:h-12
                      xl:h-16
                      uppercase
                      px-4
                      xl:px-6
                      flex
                      items-center
                      ml-4
                      font-bold
                      tracking-widest
                      transition
                      duration-500
                      ease-in-out
                      bg-header
                      hover:bg-over
                    "
                  >
                    <div>Užsakyk naujienas</div>
                    <div class="ml-2 xl:ml-4 h-6 w-6 xl:h-10 xl:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0,0H24V24H0Z" fill="none" />
                        <path
                          d="M5,13H16.17l-4.88,4.88a1.008,1.008,0,0,0,0,1.42,1,1,0,0,0,1.41,0l6.59-6.59a1,1,0,0,0,0-1.41L12.71,4.7A1,1,0,1,0,11.3,6.11L16.17,11H5a1,1,0,0,0,0,2Z"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </form>
            <div class="border-b mb-8"></div>
            <div class="flex mb-40">
              <div class="w-4 mr-2">*</div>
              <div class="content-text">
                Jūsų pateikta informacija bus naudoja tik projekto vystymui ir
                informacijos apie APMINK.LT pateikimui. Bet kuriuo metu galėsite
                atsisakyti naujienų.
              </div>
            </div>
          </div>
          <div v-else class="max-w-screen-sm mx-auto px-6">
            <div class="mt-14 flex items-end">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 80 80"
              >
                <path d="M0,0H80V80H0Z" fill="none" />
                <path
                  d="M35.333,2A33.333,33.333,0,1,0,68.667,35.333,33.345,33.345,0,0,0,35.333,2ZM26.3,49.633,14.333,37.667a3.323,3.323,0,1,1,4.7-4.7l9.633,9.6L51.6,19.633a3.323,3.323,0,0,1,4.7,4.7L31,49.633a3.32,3.32,0,0,1-4.7,0Z"
                  transform="translate(4.667 4.667)"
                  fill="#468404"
                />
              </svg>
              <div class="title-text ml-2 pb-2">
                Naujienos užsakytos sėkmingai
              </div>
            </div>
            <div class="content-text text-content mt-8">
              Dėkojame, kad užsisakėte mūsų naujienas ir atsakėte į klausimus.
            </div>
            <div class="content-text text-content mt-8">
              Informaciją apie dviračių žygių-žaidimo APMINK.LT pradžią bei kaip
              pasinaudoti dovana gausite Jūsų nurodytu el. paštu.
            </div>
            <div class="title-text mt-14">Iki greito pasimatymo!</div>
            <div class="content-text text-content mb-14">
              APMINK.LT kolektyvas
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["open"],
  emits: ["close"],
  data() {
    return {
      city: "",
      email: "",
      trukme: null,
      cityValidity: "pending",
      error: null,
      emailValidity: "pending",
      radioValidity: true,
      isSuccess: false,
      comeFrom: null,
      fbSource: null
    };
  },
  methods: {
    submitRegistration() {
      if (this.cityValidity === "valid" && this.email && this.trukme != null) {
        this.error = null;
        this.cityValidity = "pending";
        this.emailValidity = "pending";
        this.radioValidity = true;
        
        fetch(
          "https://vue-apmink-default-rtdb.europe-west1.firebasedatabase.app/registration.json",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              city: this.city,
              email: this.email,
              duration: this.trukme,
              comeFrom: this.comeFrom,
              fromFb: this.fbSource,
              time: new Date(),
            }),
          }
        )
          .then((response) => {
            if (response.ok) {
              this.email = "";
              this.city = "";
              this.trukme = null;
              this.isSuccess = true;
              this.comeFrom = null;
              this.fbSource = null;
              // window.gtag_report_conversion();
            } else {
              throw new Error(
                "Oi, nepavyko! Pabandykite vėliau ir praneškite mums."
              );
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = "Oi, nepavyko! Pabandykite vėliau ir praneškite mums.";
          });
        return;
      }

      if (this.city === "") {
        this.cityValidity = "invalid";
      }

      if (this.email === "") {
        this.emailValidity = "invalid";
      }

      if (this.trukme === null) {
        this.radioValidity = false;
      } else {
        this.radioValidity = true;
      }

      // console.log(this.radioValidity);

      // console.log(this.city);
      // console.log(this.email);
      // console.log(this.trukme);
    },
    validInput() {
      if (this.city === "") {
        this.cityValidity = "invalid";
      } else {
        this.cityValidity = "valid";
      }
    },
    validEmail() {
      if (this.email === "") {
        this.emailValidity = "invalid";
      } else {
        this.emailValidity = "valid";
      }
    },
    closeMod() {
      this.$emit("close");
      this.isSuccess = false;
    },
    conversions(){
      window.gtag_report_conversion();
    }
  },
  created(){
    // const url = location.searchParams.get("apclick");
    const stringUrl = window.location.href;
    const url = new URL(stringUrl);
    const apCome = url.searchParams.get("apclick");
    const apFb = url.searchParams.get("fbclid");
    // if(apCome) {
    //   this.comeFrom = apCome;
    // }
    // if(apFb){
    //   this.fbSource = apFb;
    // }
    this.comeFrom = apCome;
    this.fbSource = apFb;

  }
};
</script>

<style>
.invalid {
  /* @apply border-red-500 */
}

.modalbg-enter-from,
.modalbg-leave-to {
  opacity: 0;
}

.modalbg-enter-active {
  transition: all 0.3s ease-out;
}

.modalbg-leave-active {
  transition: all 0.3s ease-in;
}

.modalbg-enter-to,
.modalbg-leave-from {
  opacity: 1;
}

.modalcont-enter-from,
.modalcont-leave-to {
  transform: translateX(100%);
}

.modalcont-enter-active {
  transition: all 0.3s ease-out;
}

.modalcont-leave-active {
  transition: all 0.3s ease-in;
}

.modalcont-enter-to,
.modalcont-leave-from {
  transform: translateX(0);
}

.success-enter-from,
.success-leave-to {
  opacity: 0;
}

.success-enter-active {
  transition: all 0.3s ease-out;
}

.success-leave-active {
  transition: all 0.3s ease-in;
}

.success-enter-to,
.success-leave-from {
  opacity: 1;
}
</style>