<template>
  <div
    class="
      border-white
      header-mob
      bg-black bg-opacity-60
      p-4
      flex flex-col
      justify-between
    "
  >
    <div class="">
      <div
        class="
          bg-white
          m-1
          flex
          h-8
          sm:h-10
          rounded-lg
          sm:rounded-xl
          flex
          items-center
          justify-center
          text-sm
        "
      >
        apmink.lt
      </div>
      <div class="flex justify-between pt-4 h-24 sm:h-32">
        <transition appear @before-enter="beforeEnterBird" @enter="enterBird">
          <div class="flex">
            <img
              class="h-12 w-auto sm:h-13 ml-4 mt-4"
              src="../../assets/paukstis.png"
              alt="apmink paustis"
            />
          </div>
        </transition>
        <transition appear @before-enter="beforeEnterCloud" @enter="enterCloud">
          <div class="">
            <img
              class="h-20 sm:h-28 w-auto"
              src="../../assets/debeseliai_mob.png"
              alt="apmink paustis"
            />
          </div>
        </transition>
      </div>
      <transition appear @before-enter="beforeEnterText" @enter="enterText" >
      <div class="">
        <div class="text-xl mt-4 sm:text-28 text-white leading-snug">
          Pasinerk į <br />
          <span class="text-yellow-500">vasaros nuotykius</span> <br />
          su pažintiniais dviračių žygiais-žaidimais
        </div>
        <div class="text-5xl sm:text-6xl font-head font-bold text-white mt-6">
          AP<span class="text-yellow-500">MINK</span>.LT
        </div>
      </div>
      </transition>

      <transition appear @before-enter="beforeEnterBike" @enter="enterBike">
        <div class="mt-4 flex justify-end">
          <img src="../../assets/dviratis_mob.png" alt="" />
        </div>
      </transition>
    </div>
    <div class="flex justify-center">
      <div class="rounded border-4 border-white w-1/2 m-1"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

export default {
  methods: {
    beforeEnterText(el) {
      el.style.transform = "translateY(100px)";
      el.style.opacity = 0;
    },
    enterText(el) {
      gsap.to(el, {
        duration: 1,
        opacity: 1,
        y: 0,
      });
    },
    beforeEnterBird(el) {
      el.style.transform = "translate(1000px, 500px)";
      //   el.style.opacity = 0;
    },
    enterBird(el, done) {
      gsap.registerPlugin(MotionPathPlugin);

      gsap.to(el, {
        duration: 5,

        motionPath: {
          path: [
            { x: 400, y: 50 },
            { x: 150, y: 150 },
            { x: 0, y: 0 },
          ],
        },
        onComplete: done,
      });
    },
    beforeEnterCloud(el) {
      el.style.transform = "translateX(1000px)";
      el.style.opacity = 0;
    },
    enterCloud(el) {
      gsap.to(el, {
        duration: 3,
        x: 0,
        opacity: 1,
      });
    },
    beforeEnterBike(el) {
      el.style.transform = "translateX(-300px)";
      el.style.opacity = 0;
    },
    enterBike(el) {
      gsap.to(el, {
        duration: 2,
        x: 0,
        opacity: 1,
      });
    },
  },
};
</script>

<style scoped>
.header-mob {
  height: 550px;
  width: 300px;
  border-width: 16px;
  border-radius: 40px;
}
@media (min-width: 640px) {
  .header-mob {
    height: 650px;
    width: 370px;
    border-width: 20px;
    border-radius: 50px;
  }
}
</style>