<template>
  <transition name="header">
    <div
      v-if="showSubscribeHeader"
      class="border-t border-b fixed top-0 w-full bg-white z-30"
    >
      <div class="max-w-screen-2xl w-full mx-auto px-6">
        <div class="flex justify-between">
          <div class="py-6 hidden sm:inline">
            <!-- <div class="text-28 leading-none">Iki APMINK.LT liko:</div> -->
            <div v-if="showText" class="">
              <div class="flex items-center text-3xl md:text-4xl font-head font-bold text-header leading-none h-12">
                APMINK.LT jau greitai!
              </div>
              <div class="content-text mt-1">
                Pažink Lietuvą su dviračių turų žaidimais! 
                <br>
                Daugiau informacijos <a href="https://www.facebook.com/apmink.lt" target="_blank" class="font-bold transition duration-500 ease-in-out hover:text-over">APMINK.lt facebook puslapyje ></a>
              </div>
            </div>
            <div v-else class="">
              <div
                v-if="showCountDown && !isLoading"
                class="header-text text-header leading-none"
              >
                {{ days }}<span class="title-text">d</span> {{ hours
                }}<span class="title-text">h</span> {{ minutes
                }}<span class="title-text">m</span> {{ seconds
                }}<span class="title-text">s</span>
              </div>
              <div v-if="isLoading" class="pb-2">
                <img
                  class="h-10 md:h-12 pl-32"
                  src="../../assets/loading-spinner.gif"
                  alt=""
                />
              </div>
              <div class="content-text mt-1">
                Pažink Lietuvą su dviračių žygiais-žaidimais jau greitai!
              </div>
            </div>
          </div>
          <div class="py-4 sm:py-6 sm:border-l sm:w-5/12 sm:pl-4 md:pl-12">
            <!-- <div class="text-28 leading-none">Dovana:</div> -->
            <div
              class="
                font-head font-bold
                text-header
                leading-none
                flex
                items-center
              "
            >
              <svg
                class="h-12"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 87.76 87.76"
              >
                <path d="M0,0H87.76V87.76H0Z" fill="none" />
                <path
                  d="M67.82,16.627H59.849A10.944,10.944,0,0,0,40.4,6.937l-1.828,2.45L36.738,6.9a10.973,10.973,0,0,0-20.112,6.07,10.986,10.986,0,0,0,.658,3.657H9.313A7.257,7.257,0,0,0,2.037,23.94L2,64.164a7.288,7.288,0,0,0,7.313,7.313H67.82a7.288,7.288,0,0,0,7.313-7.313V23.94A7.288,7.288,0,0,0,67.82,16.627ZM49.537,9.313A3.657,3.657,0,1,1,45.88,12.97,3.667,3.667,0,0,1,49.537,9.313Zm-21.94,0A3.657,3.657,0,1,1,23.94,12.97,3.667,3.667,0,0,1,27.6,9.313Zm36.567,54.85H12.97a3.667,3.667,0,0,1-3.657-3.657V45.88L67.82,56.85v3.657A3.667,3.667,0,0,1,64.164,64.164ZM67.82,45.88V56.85L9.313,45.88V27.6A3.667,3.667,0,0,1,12.97,23.94H27.889l-5.412,7.386a3.65,3.65,0,1,0,5.887,4.315l10.2-13.9,10.2,13.9a3.65,3.65,0,1,0,5.887-4.315L49.244,23.94H64.164A3.667,3.667,0,0,1,67.82,27.6Z"
                  transform="translate(5.313 5.313)"
                  fill="#468404"
                />
              </svg>
              <button
                @click="openRegistration"
                class="
                  h-10
                  md:h-12
                  text-xs
                  sm:text-sm
                  md:text-lg
                  text-white
                  rounded-r30
                  uppercase
                  px-3
                  sm:px-4
                  xl:px-6
                  flex
                  items-center
                  ml-2
                  font-bold
                  tracking-widest
                  transition
                  duration-500
                  ease-in-out
                  bg-header
                  hover:bg-over
                "
              >
                <div>
                  Užsisakyk <span class="sm:hidden lg:inline">naujienas</span>
                </div>
                <div class="ml-1 xl:ml-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M0,0H24V24H0Z" fill="none" />
                    <path
                      d="M5,13H16.17l-4.88,4.88a1.008,1.008,0,0,0,0,1.42,1,1,0,0,0,1.41,0l6.59-6.59a1,1,0,0,0,0-1.41L12.71,4.7A1,1,0,1,0,11.3,6.11L16.17,11H5a1,1,0,0,0,0,2Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <div class="content-text mt-1">
              Visiems užsisakiusiems naujienas iki APMINK.lt paleidimo pradžios
              dovanosime pirmą išsirinktą dviračių žygį-žaidimą nemokamai
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  emits: ["registration"],
  props: [
    "days",
    "hours",
    "minutes",
    "seconds",
    "isLoading",
    "showCountDown",
    "showSubscribeHeader",
    "showText",
  ],
  methods: {
    openRegistration() {
      this.$emit("registration");
    },
  },
};
</script>

<style>
.header-enter-from,
.header-leave-to {
  transform: translateY(-100%);
}

.header-enter-active {
  transition: all 0.3s ease-out;
}

.header-leave-active {
  transition: all 0.3s ease-in;
}

.header-enter-to,
.header-leave-from {
  transform: translateY(0);
}
</style>