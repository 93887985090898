<template>
  <div class="w-full">
    <div class="max-w-screen-sm w-full mx-auto px-6 mt-20">
      <form @submit.prevent="submitForm">
        <div class="content-text mb-2">
          <label for="email">E-mail</label>
        </div>
        <div class="mb-6">
          <input
            class="border border-header rounded-r30 h-10 md:h-12 w-full max-w-sm px-6 text-sm md:text-base text-content"
            type="email"
            id="email"
            v-model.trim="email"
          />
        </div>
        <div class="content-text mb-2">
          <label for="password">Password</label>
        </div>
        <div class="mb-6">
          <input
            class="border border-header rounded-r30 h-10 md:h-12 w-full max-w-sm px-6 text-sm md:text-base text-content"
            type="password"
            id="password"
            v-model.trim="password"
          />
        </div>
        <p v-if="!formIsValid" class="">Įveskite galiojančius duomenis</p>
        <button class="font-head text-white rounded-r30 text-sm md:text-lg xl:text-xl h-10 md:h-12 xl:h-16 uppercase px-4 xl:px-6 flex items-center ml-4 font-bold tracking-widest transition duration-500 ease-in-out bg-header hover:bg-over">{{ submitButtonCaption }}</button>
        <!-- <button class="rounded bg-red-500" @click="switchAuthMode">{{ switchModeButtonCaption }}</button> -->
      </form>
      <div class="">
        <p v-if="error" class="">Ivyko klaida: {{ error }}</p>
        <p v-if="isLoading" class="">Loading...</p>
      </div>
    </div>
  </div>
</template>

<script>
// let timer;

export default {
  events: ["auth-admin"],
  provide: {
    // timerOut: this.timeout
  },
  data() {
    return {
      email: "",
      password: "",
      formIsValid: true,
      mode: "login",
      isLoading: false,
      error: null,
      admin: {},
      timeout: null,
    };
  },
  computed: {
    submitButtonCaption() {
      return "Login";
      // if(this.mode === 'login') {
      //     return 'Login';
      // } else {
      //     return 'Signup';
      // }
    },
    // switchModeButtonCaption() {
    //     if(this.mode === 'login') {
    //         return 'Perjungti i registruokis';
    //     } else {
    //         return 'Perjungti i prisijungima';
    //     }
    // }
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;
      try {
        if (this.mode === "login") {
          this.error = null;
          await this.loginAdmin();
          this.$emit("auth-admin", this.timeout);
        }
        // else {
        //     this.error = null;
        //     await this.signupAdmin();
        // }
      } catch (err) {
        this.error = err.message || "Nepavyko prisijungti, pabandykite vėliau";
      }

      this.isLoading = false;
    },
    // switchAuthMode() {
    //     if(this.mode === 'login'){
    //         this.mode = 'signup';
    //     } else {
    //         this.mode = 'login';
    //     }
    // },

    async loginAdmin() {
      const response = await fetch(
        "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD6XIVAyFpkj9k6ek9ZVaA8yhBrBjvQSkc",
        {
          method: "POST",
          body: JSON.stringify({
            email: this.email,
            password: this.password,
            returnSecureToken: true,
          }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        console.log(responseData);
        const error = new Error(responseData.message || "Fail to authenticate");
        throw error;
      }

      const expiresIn = +responseData.expiresIn * 1000;
      const expirationDate = new Date().getTime() + expiresIn;

      localStorage.setItem("token", responseData.idToken);
      localStorage.setItem("userId", responseData.localId);
      localStorage.setItem("tokenExpiration", expirationDate);

      this.timeout = expiresIn;

      // timer = setTimeout

      // const setAdmin = {
      //     token: responseData.idToken,
      //     userId: responseData.localId,
      //     tokenExpiration: responseData.expiresIn
      // }

      // this.admin = setAdmin;
      // console.log(this.admin);
    },
  },
};
</script>

<style>
</style>